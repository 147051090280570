export enum TaskSetView {
  GLOBAL = 'global',
  DEPLOYED = 'deployed',
  EVENT = 'event',
  COMPLETED = 'completed',
  SPAWNED = 'spawned',
}

export enum TaskSetViewTab {
  EDIT_TASK_SET,
  TASK_SET_INFO,
  COMMENTS,
  SETTINGS,
}

export enum EditTaskSetAttributes {
  NAME_EDITABLE,
  NAME_READONLY,
  TASK_LIST,
  UNLINK_TASK,
  CATEGORY_EDITABLE,
  CATEGORY_READONLY,
  ASSIGNEE_EDITABLE,
  ASSIGNEE_READONLY,
  NAME_ADDITION_EDITABLE,
  NAME_ADDITION_READONLY,
  TYPE_EDITABLE,
  TYPE_READONLY,
}

export enum TaskSetInfoAttributes {
  NAME = 'name',
  CREATED_BY = 'created-by',
  CREATED_ON = 'created-on',
  DEPLOYED_ON = 'deployed-on',
  COMPLETED_ON = 'completed-on',
  SPAWNED_ON = 'spawned-on',
  TASK_SET_ID = 'task-set-id',
  CATEGORY = 'category',
  SUMMARY_READONLY = 'summary-readonly',
  SUMMARY_EDITABLE = 'summary-editable',
  BUILDER_PUBLIC_NOTES_READONLY = 'public-notes-readonly',
  BUILDER_PUBLIC_NOTES_EDITABLE = 'public-notes-editable',
  BUILDER_PRIVATE_NOTES_EDITABLE = 'private-notes-editable',
}

export enum TaskSetCommentsAttributes {
  COMMENTS_TABLE,
  ACTIVITIES,
  ADD_COMMENT_BUTTON,
}

export enum TaskSetSettingsAttributes {
  DEPLOY,
  OPEN,
  DUPLICATE,
  DELETE,
  ATTRIBUTES,
  ATTRIBUTES_READONLY,
  SCHEDULES,
  SCHEDULED_INSTANCES,
  TOGGLE_SCHEDULE,
  COLOR_EDITABLE,
  COLOR_READONLY,
  TASK_NOTIFICATIONS,
  TASK_SET_NOTIFICATIONS,
}

export class TaskSetViewHelper {
  public static TaskSetViewTabMap = new Map<TaskSetView, TaskSetViewTab[]>([
    [
      TaskSetView.GLOBAL,
      [
        TaskSetViewTab.EDIT_TASK_SET,
        TaskSetViewTab.TASK_SET_INFO,
        TaskSetViewTab.SETTINGS,
      ],
    ],
    [
      TaskSetView.DEPLOYED,
      [
        TaskSetViewTab.EDIT_TASK_SET,
        TaskSetViewTab.TASK_SET_INFO,
        TaskSetViewTab.SETTINGS,
      ],
    ],
    [
      TaskSetView.EVENT,
      [TaskSetViewTab.EDIT_TASK_SET, TaskSetViewTab.TASK_SET_INFO],
    ],
    [
      TaskSetView.COMPLETED,
      [TaskSetViewTab.TASK_SET_INFO, TaskSetViewTab.COMMENTS],
    ],
    [
      TaskSetView.SPAWNED,
      [TaskSetViewTab.TASK_SET_INFO, TaskSetViewTab.COMMENTS],
    ],
  ]);

  public static editTaskSetMap = new Map<TaskSetView, EditTaskSetAttributes[]>([
    [
      TaskSetView.GLOBAL,
      [
        EditTaskSetAttributes.NAME_EDITABLE,
        EditTaskSetAttributes.TASK_LIST,
        EditTaskSetAttributes.UNLINK_TASK,
        EditTaskSetAttributes.CATEGORY_EDITABLE,
        EditTaskSetAttributes.TYPE_EDITABLE,
        EditTaskSetAttributes.ASSIGNEE_EDITABLE,
        EditTaskSetAttributes.NAME_ADDITION_EDITABLE,
      ],
    ],
    [
      TaskSetView.DEPLOYED,
      [
        EditTaskSetAttributes.NAME_READONLY,
        EditTaskSetAttributes.TASK_LIST,
        EditTaskSetAttributes.UNLINK_TASK,
        EditTaskSetAttributes.CATEGORY_READONLY,
        EditTaskSetAttributes.TYPE_READONLY,
        EditTaskSetAttributes.ASSIGNEE_READONLY,
        EditTaskSetAttributes.NAME_ADDITION_READONLY,
      ],
    ],
    [
      TaskSetView.EVENT,
      [
        EditTaskSetAttributes.NAME_READONLY,
        EditTaskSetAttributes.TASK_LIST,
        EditTaskSetAttributes.CATEGORY_READONLY,
        EditTaskSetAttributes.TYPE_READONLY,
        EditTaskSetAttributes.ASSIGNEE_READONLY,
        EditTaskSetAttributes.NAME_ADDITION_READONLY,
      ],
    ],
    [TaskSetView.COMPLETED, []],
    [TaskSetView.SPAWNED, []],
  ]);

  public static taskSetInfoMap = new Map<TaskSetView, TaskSetInfoAttributes[]>([
    [
      TaskSetView.GLOBAL,
      [
        TaskSetInfoAttributes.NAME,
        TaskSetInfoAttributes.CREATED_BY,
        TaskSetInfoAttributes.CREATED_ON,
        TaskSetInfoAttributes.TASK_SET_ID,
        TaskSetInfoAttributes.CATEGORY,
        TaskSetInfoAttributes.SUMMARY_EDITABLE,
        TaskSetInfoAttributes.BUILDER_PUBLIC_NOTES_EDITABLE,
        TaskSetInfoAttributes.BUILDER_PRIVATE_NOTES_EDITABLE,
      ],
    ],
    [
      TaskSetView.DEPLOYED,
      [
        TaskSetInfoAttributes.NAME,
        TaskSetInfoAttributes.DEPLOYED_ON,
        TaskSetInfoAttributes.TASK_SET_ID,
        TaskSetInfoAttributes.CATEGORY,
        TaskSetInfoAttributes.SUMMARY_READONLY,
        TaskSetInfoAttributes.BUILDER_PUBLIC_NOTES_READONLY,
      ],
    ],
    [
      TaskSetView.EVENT,
      [
        TaskSetInfoAttributes.NAME,
        TaskSetInfoAttributes.DEPLOYED_ON,
        TaskSetInfoAttributes.TASK_SET_ID,
        TaskSetInfoAttributes.CATEGORY,
        TaskSetInfoAttributes.SUMMARY_READONLY,
        TaskSetInfoAttributes.BUILDER_PUBLIC_NOTES_READONLY,
      ],
    ],
    [
      TaskSetView.COMPLETED,
      [
        TaskSetInfoAttributes.NAME,
        TaskSetInfoAttributes.COMPLETED_ON,
        TaskSetInfoAttributes.TASK_SET_ID,
        TaskSetInfoAttributes.CATEGORY,
        TaskSetInfoAttributes.SUMMARY_READONLY,
        TaskSetInfoAttributes.BUILDER_PUBLIC_NOTES_READONLY,
      ],
    ],
    [
      TaskSetView.SPAWNED,
      [
        TaskSetInfoAttributes.NAME,
        TaskSetInfoAttributes.SPAWNED_ON,
        TaskSetInfoAttributes.TASK_SET_ID,
        TaskSetInfoAttributes.CATEGORY,
        TaskSetInfoAttributes.SUMMARY_READONLY,
        TaskSetInfoAttributes.BUILDER_PUBLIC_NOTES_READONLY,
      ],
    ],
  ]);

  public static taskSetCommentsMap = new Map<
    TaskSetView,
    TaskSetCommentsAttributes[]
  >([
    [TaskSetView.GLOBAL, []],
    [TaskSetView.DEPLOYED, []],
    [TaskSetView.EVENT, []],
    [
      TaskSetView.COMPLETED,
      [
        TaskSetCommentsAttributes.COMMENTS_TABLE,
        TaskSetCommentsAttributes.ACTIVITIES,
        TaskSetCommentsAttributes.ADD_COMMENT_BUTTON,
      ],
    ],
    [
      TaskSetView.SPAWNED,
      [
        TaskSetCommentsAttributes.COMMENTS_TABLE,
        TaskSetCommentsAttributes.ACTIVITIES,
        TaskSetCommentsAttributes.ADD_COMMENT_BUTTON,
      ],
    ],
  ]);

  public static taskSetSettingsMap = new Map<
    TaskSetView,
    TaskSetSettingsAttributes[]
  >([
    [
      TaskSetView.GLOBAL,
      [
        TaskSetSettingsAttributes.DEPLOY,
        TaskSetSettingsAttributes.DUPLICATE,
        TaskSetSettingsAttributes.DELETE,
        TaskSetSettingsAttributes.SCHEDULES,
        TaskSetSettingsAttributes.COLOR_EDITABLE,
        TaskSetSettingsAttributes.TASK_NOTIFICATIONS,
        TaskSetSettingsAttributes.TASK_SET_NOTIFICATIONS,
        TaskSetSettingsAttributes.ATTRIBUTES,
      ],
    ],
    [
      TaskSetView.DEPLOYED,
      [
        TaskSetSettingsAttributes.OPEN,
        TaskSetSettingsAttributes.DELETE,
        TaskSetSettingsAttributes.SCHEDULES,
        TaskSetSettingsAttributes.COLOR_READONLY,
        TaskSetSettingsAttributes.TASK_NOTIFICATIONS,
        TaskSetSettingsAttributes.TASK_SET_NOTIFICATIONS,
        TaskSetSettingsAttributes.SCHEDULED_INSTANCES,
        TaskSetSettingsAttributes.TOGGLE_SCHEDULE,
        TaskSetSettingsAttributes.ATTRIBUTES_READONLY,
      ],
    ],
    [TaskSetView.EVENT, []],
    [TaskSetView.COMPLETED, []],
    [TaskSetView.SPAWNED, []],
  ]);

  public static getTaskSetViewTabArray(
    TaskSetView: TaskSetView,
  ): TaskSetViewTab[] {
    return this.TaskSetViewTabMap.get(TaskSetView) ?? [];
  }

  public static getEditTaskSetArray(
    TaskSetView: TaskSetView,
  ): EditTaskSetAttributes[] {
    return this.editTaskSetMap.get(TaskSetView) ?? [];
  }

  public static getTaskSetInfoArray(
    TaskSetView: TaskSetView,
  ): TaskSetInfoAttributes[] {
    return this.taskSetInfoMap.get(TaskSetView) ?? [];
  }

  public static getCommentsArray(
    TaskSetView: TaskSetView,
  ): TaskSetCommentsAttributes[] {
    return this.taskSetCommentsMap.get(TaskSetView) ?? [];
  }

  public static getSettingsArray(
    TaskSetView: TaskSetView,
  ): TaskSetSettingsAttributes[] {
    return this.taskSetSettingsMap.get(TaskSetView) ?? [];
  }
}
