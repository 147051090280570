import { inject, Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  ApiDataResponseArray,
  ApiRequestParams,
  ApiResponse,
  ApiResponseArray,
  ApiResponseMessage,
  EventTaskInfo,
} from '@models/global-interfaces';
import { Observable, throwError } from 'rxjs';
import { createHttpParams, flattenResponse } from '@services/utilities';
import { catchError, map } from 'rxjs/operators';
import {
  CreateTaskSetRequest,
  TaskSetDeploymentPayload,
  taskSetTableApiRequestParams,
  TaskSetBase,
  TaskSetResponse,
  TaskSetInfo,
  EditTaskSet,
  TaskSetOptions,
  DuplicateTaskSetRequest,
  RecallDeploymentTaskSetPayload,
  SpawnTaskSetRequest,
  TaskSetTableRecord,
  ClosedTaskSetResponse,
  ReassignTaskSetPayload,
} from '@models/task-set.model';
import * as TaskModel from '@models/task.model';
import { ClosedTaskResponse, TaskUpdateTags } from '@models/task.model';
import { TaskScope } from '@root/models/global-enum';
import { TaskSetView } from '@root/app/views/task-set/task-set-view';

@Injectable()
export class TaskSetService {
  private readonly http = inject(HttpClient);
  public apiUrl = environment.apiUrl;

  public readonly getTaskSets = (
    req: ApiRequestParams,
  ): Observable<ApiResponseArray<TaskSetTableRecord>> => {
    const params: ApiRequestParams = req ?? taskSetTableApiRequestParams;
    return this.http
      .get<TaskSetTableRecord[]>(`${this.apiUrl}task-sets`, {
        params: createHttpParams(params),
      })
      .pipe(
        map((res: TaskSetTableRecord[]) => {
          return { data: res } as ApiResponseArray<TaskSetTableRecord>;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  };

  public readonly updateTaskSet = (taskSet?: TaskSetBase): Observable<ApiResponse> => {
    return this.http.put(`${this.apiUrl}task-sets/${taskSet?.id}`, taskSet).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  };

  public readonly updateTaskSetTags = (taskSet?: TaskUpdateTags): Observable<ApiResponse> => {
    return this.http.put(`${this.apiUrl}task-sets/${taskSet?.id}`, taskSet).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  };

  public readonly createTaskSet = (req?: CreateTaskSetRequest): Observable<ApiResponse> => {
    return this.http
      .post(`${this.apiUrl}task-sets`, {
        ...req,
        task_set_name: 'New Task Set Template',
      })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        }),
      );
  };

  public readonly getTaskSetInfo = (
    taskSetID: number,
  ): Observable<TaskSetResponse<TaskSetInfo>> => {
    return this.http.get(`${this.apiUrl}task-sets/${taskSetID}/taskSetInfo`).pipe(
      map((res: TaskSetResponse<TaskSetInfo>) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  };

  public readonly getTaskSetForEdit = (
    taskSetID: number,
  ): Observable<TaskSetResponse<EditTaskSet>> => {
    return this.http.get(`${this.apiUrl}task-sets/${taskSetID}/editTaskSet`).pipe(
      map((res: TaskSetResponse<EditTaskSet>) => {
        return flattenResponse(res, true);
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  };

  public readonly getTaskSetOptions = (
    taskSetID: number,
  ): Observable<TaskSetResponse<TaskSetOptions>> => {
    return this.http.get(`${this.apiUrl}task-sets/${taskSetID}/taskSetSettings`).pipe(
      map((res: TaskSetResponse<TaskSetOptions>) => {
        return flattenResponse(res, true);
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  };

  taskSetDeployment(payload: TaskSetDeploymentPayload): Observable<ApiResponseMessage> {
    return this.http.post(`${this.apiUrl}deploy-task-set`, payload).pipe(
      map((res: ApiResponseMessage) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  spawnTaskSet(payload: SpawnTaskSetRequest): Observable<ApiResponseMessage> {
    return this.http.post(`${this.apiUrl}task-sets/spawn-task-set`, payload).pipe(
      map((res: ApiResponseMessage) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  spawnEventTaskSet(payload: SpawnTaskSetRequest): Observable<ApiResponseMessage> {
    return this.http.post(`${this.apiUrl}task-sets/spawn-event-task-set`, payload).pipe(
      map((res: ApiResponseMessage) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  duplicateTaskSet(payload: DuplicateTaskSetRequest): Observable<ApiResponseMessage> {
    return this.http.post(`${this.apiUrl}task-sets/copy`, payload).pipe(
      map((res: ApiResponseMessage) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  deleteTaskSet(id: number): Observable<ApiResponseMessage> {
    return this.http.delete(`${this.apiUrl}task-sets/${id}`).pipe(
      map((res: ApiResponseMessage) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  recallTaskSet(payload: RecallDeploymentTaskSetPayload): Observable<ApiResponse> {
    return this.http.post(`${this.apiUrl}recall-deployed-task-set`, payload).pipe(
      map((res: ApiResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  eventTaskInfo(params: EventTaskInfo): Observable<TaskModel.EventTaskInfoResponse> {
    return this.http.post(`${this.apiUrl}task-sets/event-task-set-spawn-info`, params).pipe(
      map((res: TaskModel.EventTaskInfoResponse) => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }
  public readonly getClosedTaskSets = (
    req: TaskModel.ClosedTaskRequest,
  ): Observable<ApiDataResponseArray<ClosedTaskResponse>> => {
    let url: string = `${this.apiUrl}closed-task-sets?locationID=${req.locationID}&completedStartDate=${req.completedStartDate}&completedEndDate=${req.completedEndDate}`;

    if (req.completedStartDate) {
      url += `&completedStartDate=${req.completedStartDate}`;
    }

    if (req.completedEndDate) {
      url += `&completedEndDate=${req.completedEndDate}`;
    }

    if (req.dueStartDate) {
      url += `&dueStartDate=${req.dueStartDate}`;
    }

    if (req.dueEndDate) {
      url += `&dueEndDate=${req.dueEndDate}`;
    }

    if (req.taskType) {
      url += `&taskType=${req.taskType}`;
    }
    return this.http.get(url).pipe(
      map((res: ClosedTaskSetResponse) => {
        return { data: res.closedTaskSets };
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  };

  public reassignTaskSet(payload?: ReassignTaskSetPayload): Observable<ApiResponseMessage> {
    return this.http.post(`${this.apiUrl}reassign-task-set`, payload).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return throwError(() => error);
      }),
    );
  }

  public static getTaskSetViewFromScope(scope: TaskScope): TaskSetView {
    switch (scope) {
      case TaskScope.LOCAL:
        return TaskSetView.DEPLOYED;
      case TaskScope.GLOBAL:
        return TaskSetView.GLOBAL;
      case TaskScope.DEPLOYED:
        return TaskSetView.DEPLOYED;
      case TaskScope.EVENT:
        return TaskSetView.EVENT;
      case TaskScope.COMPLETED:
        return TaskSetView.COMPLETED;
      case TaskScope.FAILED:
        return TaskSetView.COMPLETED;
      case TaskScope.SPAWNED:
        return TaskSetView.SPAWNED;
      default:
        return TaskSetView.DEPLOYED;
    }
  }
}
